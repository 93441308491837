function create(e, c, a, d, o, f) {
	var r = null;
	var el = document.createElement(e);

	if (typeof c != "undefined" && c != "" && c !== false) {
		el.className = c;
	}

	if (typeof a != "undefined" && a !== false) {
		key = Object.keys(a);
		for (let i = 0; i < key.length; i++) {
			el.setAttribute(key[i], a[key[i]]);
		}
	}

	if (typeof d != "undefined" && d !== false) {
		if (typeof d == "string") {
			if (el.nodeName == "INPUT") {
				el.value = d;
			} else {
				el.innerHTML = d;
			}
			d = false;
		} else if (typeof d == "function") {
			d = d();
			if (typeof d == "string") el.innerHTML = d;
		} else if (d instanceof Node) {
			el.appendChild(d);
			d = false;
		}
		if (typeof d == "object") {
			var key = Object.keys(d);
			for (let i = 0; i < key.length; i++) {
				if (typeof d[key[i]] == "string") {
					el.innerHTML = d[key[i]];
				} else if (typeof d[key[i]] == "function") {
					r = d[key[i]]();
					if (typeof r == "string") el.innerHTML = r;
					if (r instanceof Node) el.appendChild(r);
					r = null;
				} else if (d[key[i]] instanceof Node) {
					el.appendChild(d[key[i]]);
				}
			}
			key = null;
		}
	}

	if (typeof o != "undefined" && o !== false) {
		if (typeof o == "object") {
			key = Object.keys(o);
			for (let i = 0; i < key.length; i++) {
				el[key[i]] = o[key[i]];
			}
		} else if (typeof o == "function") {
			el.onclick = o;
		}
	}

	if (typeof f != "undefined" && typeof f == "function") f(el);

	return el;
}

export function toLetter(a) {
	if (a instanceof Node) {
		let arr = document.createDocumentFragment();
		[].forEach.call(a.textContent.split(" "), function (l) {
			arr.appendChild(
				create(
					"text",
					false,
					false,
					l.replace(/\S/g, "<le class='letter'>$&</le>")
				)
			);
			arr.appendChild(create("text-s", false, false, " "));
		});
		a.innerHTML = "";
		a.appendChild(arr);
	} else if (typeof a == "string") {
		return a.replace(/\S/g, "<le class='letter'>$&</le>");
	}
}

export function letterAnim(el, option) {
	if (el instanceof Node === false && typeof el == "string")
		el = document.querySelectorAll(el)[0];
	if (el instanceof Node === false) return false;

	let letter = el.querySelectorAll(".letter");
	let param = Object.assign(
		{
			translateX: 0, //[40,0],
			translateY: 0, //[40,0],
			translateZ: 0, //[40,0],
			opacity: 1, //[0,1],
			easing: "linear",
			duration: 200,
			delay: 40,
			plus: 0,
			clear: false
		},
		typeof option !== "object" ? new Object(null) : option
	);

	for (let i = 0; i < letter.length; i++) {
		letter[i].style.cssText = "";
		letter[i].style.transform =
			"translate3d(" +
			(param.translateX instanceof Array
				? param.translateX[0]
				: param.translateX) +
			"px, " +
			(param.translateY instanceof Array
				? param.translateY[0]
				: param.translateY) +
			"px, " +
			(param.translateZ instanceof Array
				? param.translateZ[0]
				: param.translateZ) +
			"px)";
		letter[i].style.opacity =
			param.opacity instanceof Array ? param.opacity[0] : param.opacity;
		letter[i].style.transition = "all 0ms " + param.easing;
	}

	if (param.clear == true) return true;

	let animate = function (k) {
		if (typeof letter[k] == "undefined") return true;
		letter[k].style.transform =
			"translate3d(" +
			(param.translateX instanceof Array
				? param.translateX[1]
				: param.translateX) +
			"px, " +
			(param.translateY instanceof Array
				? param.translateY[1]
				: param.translateY) +
			"px, " +
			(param.translateZ instanceof Array
				? param.translateZ[1]
				: param.translateZ) +
			"px)";
		letter[k].style.opacity =
			param.opacity instanceof Array ? param.opacity[1] : param.opacity;
		letter[k].style.transition =
			"transform " +
			param.duration +
			"ms " +
			param.easing +
			" " +
			(param.delay * k + param.plus) +
			"ms, opacity " +
			param.duration +
			"ms " +
			param.easing +
			" " +
			(param.delay * k + param.plus) +
			"ms, color 400ms ease-in-out";
		animate(k + 1);
	};

	setTimeout(function () {
		animate(0);
	}, 100);
}
