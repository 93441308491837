<template>
	<div class="page_content">
		<pre-loader ref="preLoader" />
		<section class="countries_section">
			<div class="countries">
				<div class="country ukraine"></div>
				<span class="separator">and</span>
				<div class="country gb"></div>
			</div>
		</section>
		<section class="video_section">
			<div class="logo">
				<img src="@/assets/icons/logo.svg" alt="logo" />
			</div>
			<div class="phones">
				<video
					src="@/assets/videos/promo.mp4"
					autoplay
					muted
					playsinline
					loop
				></video>
			</div>
			<img src="@/assets/images/banner_1.png" alt="" class="banner banner_1" />
			<img src="@/assets/images/banner_2.png" alt="" class="banner banner_2" />
		</section>
		<section class="about_us">
			<span class="subtitle"> Підтримаємо своїх разом! </span>
			<h1 class="title split anim">Ukrainian App</h1>
			<ul class="list">
				<li>
					<span
						>Міжнародний стартап, створений українцями, чекає на твою
						підтримку!</span
					>
				</li>
				<li>
					<span>Пошук абсолютно будь-якого товару по всьому світу!</span>
				</li>
				<li>
					<span>Заощадь на будь-якій покупці до -50%</span>
				</li>
				<li>
					<span
						>Долучайся до підтримки нового українського стартапа, що невздовзі
						може стати проривом для всіх!</span
					>
				</li>
				<li>
					<span
						>Стань частиною майбутнього успіху крутого українського
						ІТ-стартапу</span
					>
				</li>
				<li>
					<span>Віремо в наше майбутнє та перемогу!</span>
				</li>
			</ul>
			<div class="app_description anim text_slide_up">
				PricEraser - додаток, що миттєво покаже Вам найкращу ціну!
			</div>
			<div class="app_logos anim">
				<img src="@/assets/icons/apps.svg" alt="" />
			</div>
		</section>
		<section class="kickstarter_video">
			<div class="video" @click="openVideoFullScreen()">
				<video
					src="@/assets/videos/kickstarter_video.mp4"
					poster="@/assets/images/video_preview.jpeg"
					playsinline
				></video>
			</div>
		</section>
		<section class="banners_section jarallax">
			<div class="jarallax-img"></div>
			<div class="banners_container">
				<div class="title_block">
					<span class="title">
						Переваги <br />
						пакету додатку
					</span>
					<img src="@/assets/icons/pro.svg" alt="" class="icon" />
				</div>
				<div class="banners swiper">
					<div class="swiper-wrapper">
						<div
							class="banner swiper-slide"
							:key="banner.title"
							v-for="banner in banners"
						>
							<div class="icon" :style="{ backgroundColor: banner.color }">
								<img :src="banner.icon" alt="" />
							</div>
							<span class="title">{{ banner.title }}</span>
							<p class="description">
								{{ banner.description }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="kickstarter_description">
			<div class="text">
				<span class="title anim text_slide_up">
					Кожен внесок дуже важливий для нас!
				</span>
				<p class="description anim text_slide_up">
					Можливо саме твоя допомога стане вирішальною у цому проекті. Ми щіро
					віримо у нашу перемогу та унікальний продукт для всього світу, завдяки
					якому Українці зможуть заощаджувати від 20% свого бюджету на
					повсякденних покупках. Приєднуйтесь до нас!
				</p>
			</div>
			<div class="instruction">
				<span class="title anim text_slide_up">
					Підтримати проект зовсім не важко!
				</span>
				<div class="swiper instruction_slider">
					<div class="swiper-wrapper">
						<div class="swiper-slide">
							<img src="@/assets/images/instruction/1.jpg" alt="" />
						</div>
						<div class="swiper-slide">
							<img src="@/assets/images/instruction/2.jpg" alt="" />
						</div>
						<div class="swiper-slide">
							<img src="@/assets/images/instruction/3.jpg" alt="" />
						</div>
						<div class="swiper-slide">
							<img src="@/assets/images/instruction/4.jpg" alt="" />
						</div>
					</div>
				</div>
			</div>
			<div class="icon arrow_down"></div>
			<div class="kickstarter_block">
				<div class="kickstarter_logo">
					<img src="@/assets/icons/kickstarter.svg" alt="" />
				</div>
				<a
					href="https://www.kickstarter.com/projects/priceraser/priceraser-smart-way-to-do-shopping/pledge/new?clicked_reward=false"
					target="_blank"
					class="kickstarter_link"
				>
					<p class="change">
						<span> Back this project </span>
						<span>Підтримати проект</span>
					</p>
				</a>
			</div>
		</section>
		<section class="together_to_win text_slide_up anim">
			<div class="icon ukraine"></div>
			<span class="title split anim">Разом до перемоги!</span>
		</section>
		<horizontal-lock />
		<fixed-button text1="Back this project" text2="Підтримати проект" />
	</div>
</template>

<script>
import { jarallax } from "jarallax";
import Rellax from "rellax";
import Swiper from "swiper";
import "swiper/swiper.min.css";
import { scrollAnimationRefresh } from "@/plugins/Animation.js";
import PreLoader from "@/components/Loader/PreLoader.vue";
import HorizontalLock from "@/components/Modals/HorizontalLock.vue";
import FixedButton from "@/components/Buttons/FixedButton.vue";
export default {
	components: { PreLoader, HorizontalLock, FixedButton },
	data() {
		return {
			banners: [
				{
					icon: require("@/assets/icons/banners/1.svg"),
					color: "#326cff",
					title: "Підтримка",
					description:
						"Будемо вдячні Вам за будь-яку суму на підтримку проекту! Тому що це дасть нам впевненість та можливість втілити всі ідеї!"
				},
				{
					icon: require("@/assets/icons/banners/2.svg"),
					color: "#fed82d",
					title: "480грн ≈ 12$",
					description:
						"Отримайте перший доступ до безкоштовної версії додатку, як тільки вона буде опублікована. Ми запросимо вас особисто."
				},
				{
					icon: require("@/assets/icons/banners/3.svg"),
					color: "#34a751",
					title: "920грн ≈ 23$",
					description:
						"Передплата на безлімітний сервіс на 6 місяців. Без обмеження кількості пошукових запитів без реклами."
				},
				{
					icon: require("@/assets/icons/banners/4.svg"),
					color: "#dd252b",
					title: "1360грн ≈ 34$",
					description:
						"Безлімітний сервіс на 12 місяців. Весь функціонал додатку без обмеження кількості пошукових запитів без реклами."
				},
				{
					icon: require("@/assets/icons/banners/5.svg"),
					color: "#723c7d",
					title: "2280грн ≈ 57$",
					description:
						"Безлімітне користування сервісом! Весь функціонал додатку без обмеження кількості пошукових запитів без реклами."
				}
			],
			videoStarted: false
		};
	},

	methods: {
		openVideoFullScreen() {
			var myVideo = document.querySelector(".kickstarter_video video");
			if (myVideo.requestFullscreen) {
				myVideo.requestFullscreen();
			} else if (myVideo.msRequestFullscreen) {
				myVideo.msRequestFullscreen();
			} else if (myVideo.mozRequestFullScreen) {
				myVideo.mozRequestFullScreen();
			} else if (myVideo.webkitRequestFullScreen) {
				myVideo.webkitRequestFullScreen();
			}
			if (!this.videoStarted) {
				myVideo.play();
				this.videoStarted = true;
			}
			myVideo.setAttribute("controls", "true");
		}
	},

	mounted() {
		jarallax(document.querySelectorAll(".jarallax"), {
			speed: 0.2
		});
		setTimeout(() => {
			new Swiper(".banners_container .banners", {
				slidesPerView: "auto",
				grabCursor: true,
				//autoHeight: true,
				freeMode: true
				//centered: true,
				//loop: true,
			});
			new Swiper(".instruction_slider", {
				slidesPerView: "auto",
				grabCursor: true,
				//autoHeight: true,
				freeMode: true
				//centered: true,
				//loop: true,
			});
			scrollAnimationRefresh();
		}, 200);
		setTimeout(() => {
			this.$refs.preLoader.hideLoader();
		}, 1000);
		setTimeout(() => {
			document.querySelector(".video_section").classList.add("active");
		}, 3500);
		setTimeout(() => {
			new Rellax(".banner_1", {
				speed: 0.5,
				center: false,
				wrapper: null,
				round: true,
				vertical: true,
				horizontal: false
			});
			new Rellax(".banner_2", {
				speed: -0.5,
				center: false,
				wrapper: null,
				round: true,
				vertical: true,
				horizontal: false
			});
		}, 3000);
	}
};
</script>

<style lang="scss" scoped>
.countries_section {
	display: flex;
	justify-content: center;
	padding: 1.6em 0;
}
.countries {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #e9e9e9;
	box-shadow: 5.624px 24.359px 68px 0px rgba(0, 0, 0, 0.06);
	border-radius: 12px;
	padding: 0.7em 1em;
	.separator {
		font-size: 1.2em;
		font-weight: 500;
		text-transform: uppercase;
		color: black;
		padding: 0 1.1em;
	}
}
.country {
	width: 3.1em;
	height: 2em;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
	&.ukraine {
		background-image: url("@/assets/icons/ukraine.svg");
	}
	&.gb {
		background-image: url("@/assets/icons/gb.svg");
	}
}
.video_section {
	position: relative;
	margin-top: 0.8em;
	overflow: hidden;
	&.active {
		.banner {
			&.banner_1 {
				bottom: 5.7em;
				opacity: 1;
			}
			&.banner_2 {
				top: 10.5em;
				opacity: 1;
			}
		}
	}
	.logo {
		display: flex;
		justify-content: center;
		img {
			width: 13.8em;
		}
	}
	.phones {
		margin-top: 2.4em;
		video {
			width: 110%;
			max-width: unset;
			margin-left: 0.4em;
		}
	}
	.banner {
		position: absolute;
		height: auto;
		opacity: 0;
		transition: all 50ms linear, top 600ms ease-in-out, bottom 600ms ease-in-out,
			opacity 600ms ease-in-out;
		&.banner_1 {
			bottom: 4.7em;
			right: -0.413em;
			width: 11.75em;
			transition: all 50ms linear, top 600ms 300ms ease-in-out,
				bottom 600ms 300ms ease-in-out, opacity 600ms 300ms ease-in-out;
		}
		&.banner_2 {
			top: 11.5em;
			left: 3.75em;
			width: 11.75em;
		}
	}
}
.about_us {
	padding: 0 1.875em;
	margin-top: 1em;
	.subtitle {
		font-size: 1.313em;
		font-weight: 600;
		color: $grey;
	}
	.title {
		margin-top: 0.1em;
		font-size: 2.5em;
		font-weight: 600;
		:deep() {
			text {
				&:nth-child(3) {
					color: $blue;
				}
			}
		}
	}
	.list {
		margin-top: 1em;
		li {
			position: relative;
			padding-left: 1.1em;
			&:before {
				content: "";
				position: absolute;
				top: 9px;
				left: 0;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: black;
			}
		}
		span {
			font-size: 0.875em;
			line-height: 1.65;
			display: block;
		}
	}
	.app_description {
		font-size: 1.6em;
		font-weight: 700;
		margin-top: 0.913em;
	}
	.app_logos {
		margin: 1.1em -0.4em;
		opacity: 0;
		transform: translateY(10px);
		transition: all 600ms ease-in-out;
		&.active {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
.kickstarter_video {
	padding: 2.025em 1em 3.75em;
	.video {
		height: 11.3em;
		border-radius: 17px;
		overflow: hidden;
		//opacity: 0;
		transition: $defaultTransition;
		// &.active {
		// 	opacity: 1;
		// }
		video {
			object-fit: cover;
		}
	}
}
.banners_section {
	position: relative;
	height: auto;
	.jarallax-img {
		background-image: url(@/assets/images/banners_parallax.jpg);
	}
	.banners_container {
		padding: 4.8em 1.4em 2.8em;
		overflow: hidden;
	}
	.title_block {
		position: relative;
		display: flex;
		padding: 0 0.5em;
		line-height: 1.4;
		.title {
			font-size: 2.1em;
			font-weight: 700;
		}
		.icon {
			position: absolute;
			top: -2.8em;
			right: 1.6em;
			width: 6em;
			height: 6em;
		}
	}
	.banners {
		margin-top: 2.125em;
	}
	.banner {
		display: flex;
		flex-direction: column;
		width: 76.2%;
		height: auto;
		margin-right: 26px;
		padding: 1.4em;
		border: 1px solid rgb(233, 233, 233);
		background-color: white;
		border-radius: 16px;
		box-shadow: 5.624px 24.359px 68px 0px rgba(0, 0, 0, 0.06);
		.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 4.4em;
			height: 4.4em;
			background-color: $blue;
			border-radius: 50%;
			img {
				width: 55%;
				height: auto;
			}
		}
		.title {
			display: block;
			margin-top: 0.8em;
			font-size: 1.5em;
			font-weight: 700;
		}
		.description {
			margin-top: 0.7em;
			font-size: 0.75em;
			line-height: 1.6;
		}
	}
}
.kickstarter_description {
	padding: 3.7em 0 0;
	.text {
		padding: 0 2em;
	}
	.title {
		font-weight: 700;
		font-size: 1.6em;
	}
	.description {
		font-size: 0.85em;
		margin-top: 1.2em;
		line-height: 1.65;
	}
	.instruction {
		margin-top: 2.5em;
		padding: 2em;
		background-color: #f3f3f3;
		.instruction_slider {
			margin-top: 1.6em;
		}
		.swiper-slide {
			width: 93%;
			margin-right: 20px;
			border-radius: 16px;
			border: 1px solid #e9e9e9;
			overflow: hidden;
			line-height: 0;
			box-shadow: 5.624px 24.359px 68px 0px rgba(0, 0, 0, 0.1);
		}
	}
	.arrow_down {
		margin: 1.4em auto 0;
		width: 2.5em;
		height: 2.5em;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
		background-image: url(@/assets/icons/arrow_down.svg);
	}
	.kickstarter_block {
		padding: 0 1.5em;
	}
	.kickstarter_logo {
		width: 73%;
		margin: 2.8em auto 0;
	}
	.kickstarter_link {
		display: block;
		margin-top: 2em;
		padding: 0.8em;
		background-color: #028859;
		color: white;
		text-align: center;
		font-size: 0.875em;
		.change {
			height: 20px;
		}
	}
}
.together_to_win {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 3.063em;
	.icon {
		width: 3.2em;
		height: 2.1em;
		background-image: url("@/assets/icons/ukraine.svg");
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: contain;
	}
	.title {
		display: block;
		margin-top: 0.8em;
		font-size: 1.6em;
		font-weight: 700;
	}
}
@media (min-width: 768px) {
	.video_section {
		.phones {
			video {
				width: 100%;
				margin: 0;
			}
		}
	}
}
</style>
