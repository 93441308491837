<template>
	<transition name="show">
		<div class="child_Preloader" v-if="isShow">
			<div class="loader">
				<div class="circle"></div>
				<div class="circle"></div>
				<div class="circle"></div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			isShow: true
		};
	},

	methods: {
		showLoader() {
			this.isShow = true;
		},

		hideLoader() {
			this.isShow = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.child_Preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	opacity: 1;
	z-index: 999999999;
	transition: all 500ms ease-in-out;
	&.show-enter-active,
	&.show-leave-active {
		opacity: 0;
	}
}
.child_Preloader.show {
	opacity: 1;
	display: block;
}
.loader {
	position: fixed;
	top: 50%;
	left: 50%;
	height: 10rem;
	width: 10rem;
	transform: translateX(-50%) translateY(-50%);
}

.loader > .circle {
	position: absolute;
	height: inherit;
	width: inherit;
	background: $blue;
	border-radius: 50%;
	animation: animate-circle 2s cubic-bezier(0.9, 0.24, 0.62, 0.79) infinite;
}
.loader > .circle:nth-of-type(1) {
	-webkit-animation-delay: 0;
	animation-delay: 0;
}

.loader > .circle:nth-of-type(2) {
	-webkit-animation-delay: calc(2s / -3);
	animation-delay: calc(2s / -3);
}

.loader > .circle:nth-of-type(3) {
	-webkit-animation-delay: calc(2s / -6);
	animation-delay: calc(2s / -6);
}

@keyframes animate-circle {
	from {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	to {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0;
	}
}
</style>
