<template>
	<transition name="show">
		<div class="fast_sale_button" v-show="show">
			<a
				href="https://www.kickstarter.com/projects/priceraser/priceraser-smart-way-to-do-shopping/pledge/new?clicked_reward=false"
				target="_blank"
				class="button"
			>
				<p class="change">
					<span> {{ text1 }} </span>
					<span> {{ text2 }} </span>
				</p>
			</a>
		</div>
	</transition>
</template>

<script>
export default {
	props: {
		text1: {
			type: String,
			required: true
		},
		text2: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			show: false
		};
	},

	mounted() {
		if (window.innerWidth <= 768 && this.type !== "call") {
			window.addEventListener("scroll", this.showOnScroll);
		}
	},

	beforeUnmount() {
		if (window.innerWidth <= 768 && this.type !== "call") {
			window.removeEventListener("scroll", this.showOnScroll);
		}
	},

	methods: {
		showOnScroll() {
			if (
				scrollY >=
				document.querySelector(".banners_section").getBoundingClientRect().top +
					scrollY
			) {
				this.hideButton();
				console.log("1");
			} else if (
				scrollY >=
				document
					.querySelector(".about_us .app_description")
					.getBoundingClientRect().top
			) {
				this.showButton();
				console.log("2");
			} else {
				this.hideButton();
				console.log("3");
			}
		},

		showButton() {
			this.show = true;
		},

		hideButton() {
			this.show = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.fast_sale_button {
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0.913em 1.45em;
	transform: translateX(0);
	justify-content: center;
	align-items: center;
	opacity: 1;
	transition: all 400ms ease-in-out;
	z-index: 9999;
	&.show-enter-active,
	&.show-leave-active {
		transform: translateY(100%);
	}
	.button {
		position: relative;
		width: 100%;
		padding: 0.72em 0;
		background-color: #028859;
		border-radius: 8px;
		overflow: hidden;
		&:after {
			content: "";
			position: absolute;
			top: -110%;
			left: -210%;
			width: 200%;
			height: 200%;
			opacity: 1;
			transform: rotate(0deg);
			z-index: 10;
			background: rgba(255, 255, 255, 0.13);
			background: linear-gradient(
				to right,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 0) 85%,
				rgba(255, 255, 255, 0.2) 98%,
				rgba(255, 255, 255, 0) 100%
			);
			animation: shape 8800ms ease infinite;
		}
	}
	p {
		height: 19px;
		transition: all 400ms ease-in-out;
		span {
			display: block;
			font-size: 0.875em;
			color: white;
			font-weight: 500;
			transform: translateY(0);
		}
	}
}
@media (max-width: 768px) {
	.fast_sale_button {
		display: flex;
	}
}
@keyframes shape {
	0% {
		top: -110%;
		left: -210%;
	}
	80% {
		top: -110%;
		left: -210%;
	}
	100% {
		top: -30%;
		left: -30%;
	}
}
</style>
