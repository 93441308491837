import * as Splitter from "@/plugins/Splitter";
import { gsap } from "gsap/dist/gsap.min";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.min";
gsap.registerPlugin(ScrollTrigger);

function scrollAnimationInit() {
	for (let i = 0; i < document.querySelectorAll(".split").length; i++) {
		Splitter.toLetter(document.querySelectorAll(".split")[i]);
	}
	let element = gsap.utils.toArray(document.querySelectorAll(".split"));
	element.forEach((block) => {
		// let delay = 0;
		// if (block.getAttribute("data-delay")) {
		// 	delay = block.getAttribute("data-delay");
		// }
		ScrollTrigger.create({
			trigger: block,
			toggleClass: "active",
			start: "top 90%",
			end: "top 5%",
			onEnter: function () {
				Splitter.letterAnim(block, {
					translateX: [10, 0],
					opacity: [0, 1],
					duration: 450,
					delay: 50,
					plus: 0
				});
			},
			onEnterBack: function () {
				Splitter.letterAnim(block, {
					translateX: [10, 0],
					opacity: [0, 1],
					duration: 450,
					delay: 50,
					plus: 0
				});
			},
			onLeave: function () {
				Splitter.letterAnim(block, {
					translateX: [0, -10],
					opacity: [1, 0],
					duration: 250,
					delay: 40,
					plus: 0
				});
			},
			onLeaveBack: function () {
				Splitter.letterAnim(block, {
					translateX: [0, -10],
					opacity: [1, 0],
					duration: 250,
					delay: 40,
					plus: 0
				});
			}
		});
	});
	let anims = gsap.utils.toArray(document.querySelectorAll(".anim"));
	anims.forEach((block) => {
		ScrollTrigger.create({
			trigger: block,
			toggleClass: "active",
			once: true,
			start: "top 90%",
			end: "top 5%"
		});
	});
}

export function scrollAnimationRefresh() {
	let Alltrigger = ScrollTrigger.getAll();
	for (let i = 0; i < Alltrigger.length; i++) {
		Alltrigger[i].kill(true);
	}
	scrollAnimationInit();
	ScrollTrigger.refresh();
	setTimeout(() => {
		// if (parallax) {
		// 	parallax.destroy();
		// }
		// createParallax();
	}, 300);
}
