<template>
	<router-view />
</template>

<script>
export default {
	components: {}
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
</style>
