<template>
	<div class="rotate">
		<div class="inner">
			<div class="phone-icon_container">
				<div class="phone-icon_drawing">
					<div class="phone-icon_display"></div>
				</div>
				<div class="phone-icon_text">
					<span>Поверніть у вертикальне положення</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	mounted() {
		window.addEventListener(
			"orientationchange",
			function () {
				if (window.orientation == 90 || window.orientation == -90) {
					document.querySelector(".rotate").classList.add("active");
				}
				if (window.orientation == 0) {
					document.querySelector(".rotate").classList.remove("active");
				}
			},
			false
		);
	}
};
</script>

<style lang="scss" scoped>
.rotate {
	display: none;
	width: 100%;
	height: 100%;
	background: black;
	z-index: 999999999999;
	position: fixed;
	top: 0;
	left: 0;
	color: white;
	&.hidden {
		display: none !important;
	}
	&.active {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.inner {
		.phone-icon_container {
			.phone-icon_drawing {
				border: 2px solid white;
				border-radius: 4px;
				width: 50px;
				height: 100px;
				margin: 0 auto 16px;
				position: relative;
				overflow: hidden;
				transform: rotate(-90deg);
				animation: rotateDevice 3.2s ease-in-out infinite alternate;
				&:before {
					content: "";
					position: absolute;
					background: white;
					border-radius: 8px;
					width: 34px;
					height: 10px;
					top: -5px;
					left: calc(50% - 17px);
				}
				&:after {
					content: "";
					position: absolute;
					background: white;
					border-radius: 2px;
					height: 2px;
					width: 70%;
					left: 15%;
					bottom: 3px;
				}
			}
			.phone-icon_text {
				font-weight: 300;
			}
		}
	}
}

@keyframes rotateDevice {
	0% {
		transform: rotate(-90deg);
	}
	50%,
	100% {
		transform: rotate(0deg);
	}
}
</style>
